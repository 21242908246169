import React, { Component} from 'react'
import {Link, navigate} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import {
	Container,
	Image,
	Grid,
	Header,
	Loader,
	Message,
	Pagination

} from 'semantic-ui-react'
import * as styles from './styles/pages.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'

const Negosyo = require('../../lib/negosyo')

export default class SeminarPage extends Component {
	state = {
		products: undefined,
		masterProducts: undefined,
		shown:5,
		mobile:undefined,
		limit:18,	// Should be Divisible by 6 (3 column, 2 column, 1 column ok)
		currentPage:1,
		paginationRange:4,
		loadingProducts:true,
		timeOut1:undefined
	};

	componentDidMount() {
		this.fetchData();
		this.handler();

		window.addEventListener('resize', this.resizeTimerFunc);
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);

	}

	componentDidUpdate(prevProps, prevState) {
		const {location} = this.props
		const {currentPage} = this.state
		const parameters = location.search.split("&");
		var curparam = []
		var tempPage = -1;
		var pageBool = false;

		for( let i = 0; i<parameters.length;i++) {
			curparam = parameters[i].split("=");
			if (curparam[0]==="?page" || curparam[0]==="page") {
				if (curparam.length > 1) {
					if( !isNaN(parseInt(curparam[1], 10)) ) {
						pageBool = true;
						tempPage = parseInt(curparam[1], 10);
					}
				}
			}
		}

		if(pageBool===false) {
			if (currentPage !== 1) {
				this.setState({currentPage:1})
			}
		} else if (tempPage !== currentPage && tempPage > 0) {
			this.setState({currentPage:tempPage})
		}
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	mapBanner = (tag) =>{
		return (
			<React.Fragment key={tag.url}>
				{tag.mainImageHref && (
					<Image alt={tag.name} style={{display:"inline-block",width:"30%", marginRight:"2%"}} className={styles.categoryBanner+" "+styles.contentBanner} as={Link} to={`/seminar/${tag.url}/`} fluid src={tag.mainImageHref}/>
				)}

			</React.Fragment>
		)
	}

	handler = () => {
		//computer

		if (window.innerWidth > tabletMaxWidth ) {

			this.setState({
				shown:5,
				mobile:false,
				paginationRange:4
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown : 5,
				mobile:true,
				paginationRange:2
			})
		} else {  //mobile
			this.setState({
				shown : 2,
				mobile:true,
				paginationRange:1
			})
		}

	}


	fetchData = () => {
		Negosyo.getSeminarAll().then((response)=> {
			if(Array.isArray(response) ) {
				this.setState({
					loadingProducts:false,
					masterProducts:response,
					products:response,
				})
			}else {
				//this assumes the response is an error if the response is not an array
				this.setState({
					loadingProducts:false,
					masterProducts:[],
					products:[],
				})

			}
		}).catch((err)=> {
			this.setState({loadingProducts:false})
		})
	}

	render() {
		const location = this.props.location
		const {
			products,
			shown,
			currentPage,
			limit,
			paginationRange,
			loadingProducts
		} =this.state

		let slicedProducts
		let length = 0
		let totalPages;
		if(products) {
			slicedProducts = products
			length = slicedProducts.length
			totalPages=Math.ceil(length/limit)
			slicedProducts = slicedProducts.slice( (currentPage-1)*limit, limit*currentPage)
		}
		let subHeader = ""
		if(loadingProducts) {
			subHeader = "Please standby ..."
		}else if(length) {
			subHeader = ``
		}
		return (
			<Layout location={location} backButton={true}>
				<Seo
					title={`Seminars`}
					description={"Join seminars to help entrepreneurs like yourself. Learn from the experts and gain valuable insights on how to create and manage a successful business. Get the tools and skills to achieve success with our comprehensive seminars."}
					/>
				<Container className={styles.container}>
					<Grid >
						<Grid.Column width={16}>
							<Header className={styles.superHeaderMod}>
								<h1>Seminars</h1>
							</Header>
							{ !loadingProducts && slicedProducts ? (
								<React.Fragment>
									<div className={`${styles.centerText} ${styles.text}`}>
										{subHeader!=="" && (
											<React.Fragment>
												<br/>
												{subHeader}
												<br/>
												<br/>
											</React.Fragment>
										)}
									</div>
									{slicedProducts.length > 0 ? (
										<div>
											{slicedProducts.map(this.mapBanner)}
										</div>
									):(
										<Message negative className={`${styles.msg} ${styles.centerText}`}>
											<Message.Header>
												Come back again for upcoming seminars
											</Message.Header>
										</Message>
									)}
									{ totalPages > 1 && (
										<React.Fragment>
											<br/>
											<Pagination
												activePage={currentPage}
												onPageChange={(e, {activePage})=>{
													navigate(`${location.pathname}?page=${activePage}`)
												}}
												ellipsisItem={null}
												boundaryRange={null}
												siblingRange={paginationRange}
												totalPages={totalPages}
												key="Pagination"
												pageItem={{
													className:styles.item
												}}
												firstItem={{
													className:styles.item,
													content:"<<"
												}}
												lastItem={{
													className:styles.item,
													content:">>"
												}}
												nextItem={{
													className:styles.item,
													content:">"
												}}
												prevItem={{
													className:styles.item,
													content:"<"
												}}
											/>
										</React.Fragment>
									)}
								</React.Fragment>
							) : (
								<Loader active inline="centered" key={"loader"}/>
							)}

						</Grid.Column>
					</Grid>
				</Container>
			</Layout>
		)
	}
}



